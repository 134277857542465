import React from 'react'
import styles from './LandingCard.module.css'
import { FaChevronCircleRight } from "react-icons/fa";
import { Link } from 'react-router-dom';

const LandingCard = ({ title, _id, cover, }) => {
  return (
    <Link to={'/land/' + _id} className={styles.container}>
      {/* {} */}
      <img className={styles.img} src={'https://greencamel.zyberapi.site/uploads/' + cover} alt="" />
      <div className={styles.title}>
        <h2>{title}</h2>
        <div style={{ width: '230px' }} className="space-between">
          <p></p>
          <FaChevronCircleRight color='white' size={25} />
        </div>
      </div>
    </Link>
  )
}

export default LandingCard
