import React, { useState } from 'react'
import styles from './UserPanel.module.css'
import { FaStar } from "react-icons/fa6";
import { IoIosArrowDropright } from "react-icons/io";
import { Link } from 'react-router-dom';

const UsersPanel = ({ users }) => {
  const [toggle, setToggle] = useState(0);

  return (
    <div className={styles.container}>
      <div className={styles.toggle}>
        <button onClick={() => setToggle(0)} className={toggle === 0 ? styles.active : styles.disable}>
          Guide
        </button>
        <button onClick={() => setToggle(1)} className={toggle === 1 ? styles.active : styles.disable}>
          Driver
        </button>
      </div>
      {users.filter((e) => toggle === 0 ? e.role === 'guide' : e.role === 'driver').map((e) => (
        <Link to={'/profile/' + e._id} className={styles.user}>
          <div className="space-between">
            <img className={styles.profile} src={"https://greencamel.zyberapi.site/uploads/" + e.profile} alt="" />
            <div className='column'>
              <h3 className={styles.name}>{e.name}</h3>
              <p className={styles.role}>{e.role}</p>
            </div>
          </div>
          <div className='space-between'>
            <FaStar color='#FFD700' size={25} />
            <p>4.8</p>
          </div>
          <IoIosArrowDropright size={30} />
        </Link>
      ))}
    </div>
  )
}

export default UsersPanel
