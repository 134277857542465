import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
// import Navbar from './Components/Navbar/Navbar';
import Profile from './Pages/Profile/Profile';
import Land from './Pages/Land/Land';
import LoginPage from './Pages/Login/LoginPage';
import SignUpPage from './Pages/Login/SignUp/SignUpPage';

function App() {
  window.localStorage.removeItem('user');
  return (
    <>
      <Router>
        {/* <Navbar /> */}
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/profile/:id' element={<Profile />} />
          <Route path='/land/:id' element={<Land />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/signup' element={<SignUpPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
