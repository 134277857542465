import React, { useEffect, useState } from 'react'
import LandingCard from '../../Uttils/Card/LandingCard'
import styles from './Home.module.css'
import UsersPanel from '../../Components/UsersPanel/UsersPanel'
import Navbar from '../../Components/Navbar/Navbar'
import axios from 'axios'

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [lands, setLands] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (isLoading) {
      axios.get('https://greencamel.zyberapi.site/category')
        .then((e) => setLands(e.data.data))

      axios.get('https://greencamel.zyberapi.site/user')
        .then((e) => {
          setUsers(e.data.data);
          setIsLoading(false);
        })
        .catch((e) => console.log(e))
    }
  })
  return (
    <>
      <Navbar isHome={true} />
      {isLoading ?
        <div className="loader-container"><div className="loader"></div></div> :
        <div className={styles.container}>
          {/* {} */}
          <div className="scroll-row">
            <div className="row">
              {lands.map((e) => (
                <LandingCard title={e.title} cover={e.cover} _id={e._id} />
              ))}
            </div>
          </div>
          <UsersPanel users={users.filter((e) => e.isBan === false)
          } />
        </div>}
    </>
  )
}

export default Home
