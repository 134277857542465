import React, { useState } from 'react'
import styles from './Navbar.module.css'
import { RiAddBoxLine } from "react-icons/ri";
import { FaChevronLeft } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { PiUserCircleThin } from "react-icons/pi";
import { IoIosLogOut } from "react-icons/io";
import AddSocialMediaModal from '../../Uttils/Modal/AddSocialMediaModal';

const Navbar = ({ isHome }) => {
  const navigate = useNavigate();
  const [isModal, setIsModal] = useState(false);

  const closeModal = () => {
    setIsModal(false);
  }

  const userInfo = JSON.parse(window.localStorage.getItem('user'));

  return (
    <>
      {isModal ? <AddSocialMediaModal closeModal={closeModal} /> : null}
      {isHome ?
        <div className={styles.container}>
          <h3>Green Camel</h3>
          <div className='line' style={{ gap: '10px' }}>
            {userInfo ? <RiAddBoxLine size={35} className={styles.icon}
              onClick={() => setIsModal(true)}
            /> : null}
            {/* {userInfo.name} */}
            {/* <PiUserCircleThin /> */}
            {userInfo ? <IoIosLogOut className={styles.icon} color='red' size={35} onClick={() => {
              window.localStorage.removeItem('user');
            }} /> :
              <Link to='/login'><PiUserCircleThin size={40} color='white' className={styles.icon} /></Link>}
          </div>
        </div>
        :
        <div>
          <div className={styles.container}>
            <FaChevronLeft onClick={() => navigate(-1)} size={25} className={styles.icon} />
            <h3>Green Camel</h3>
            {/* <RiAddBoxLine size={30} className={styles.icon} /> */}
            {/* <PiUserCircleThin className={styles.icon} /> */}
          </div>
        </div>
      }
    </>
  )
}

export default Navbar
