import React from 'react'
import styles from './PlanPrice.module.css'
import { FaCheckCircle } from "react-icons/fa";

const PlanPrice = ({ name, price, link }) => {
  return (
    <div className={styles.card}>
      {/* {} */}
      <div className={styles.header}>
        <h2>${price}</h2>
        <p>/{name}</p>
      </div>
      <div className={styles.desc}>
        <FaCheckCircle color='green' size={30} />
        <a href={link}>Download plan</a>
      </div>
    </div>
  )
}

export default PlanPrice
