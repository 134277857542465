import axios from 'axios';
import React, { useState } from 'react'

const AddSocialMediaModal = ({ closeModal }) => {
    const [fb, setFb] = useState('');
    const [ig, setIg] = useState('');
    const [kakao, setKakao] = useState('');
    const [wechat, setWechat] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const userInfo = JSON.parse(window.localStorage.getItem('user'));

    const addSocialMedia = () => {
        setIsLoading(true);
        axios.put('https://greencamel.zyberapi.site/user/' + userInfo._id, {
            kakoa: kakao,
            platform: wechat,
            ig: ig,
            fb: fb
        })
            .then((e) => {
                window.location.reload(false);
                // window.localStorage.setItem('user', e.data.data)
                setIsLoading(false);
            })
            .catch((e) => console.log(e));
    }

    return (
        <>
            {isLoading ? <div className="loader-container"><div className="loader"></div></div> :
                <div className='blur_container'>
                    <div className="modal">
                        <div className="two-line">
                            <h2>Social Media's</h2>
                            <h2 onClick={closeModal} style={{ cursor: 'pointer' }}>X</h2>
                        </div>
                        <label htmlFor="">
                            Facebook:
                            <input onChange={(e) => setFb(e.target.value)} type="text" name="" id="" />
                        </label>
                        <label htmlFor="">
                            Kakao:
                            <input onChange={(e) => setKakao(e.target.value)} type="text" name="" id="" />
                        </label>
                        <label htmlFor="">
                            Instagram:
                            <input onChange={(e) => setIg(e.target.value)} type="text" name="" id="" />
                        </label>
                        <label htmlFor="">
                            Wechat:
                            <input onChange={(e) => setWechat(e.target.value)} type="text" name="" id="" />
                        </label>
                        <div className="two-line" style={{ gap: '10px' }}>
                            <button onClick={addSocialMedia} style={{ padding: '10px 40px' }} className='white-button'>Save</button>
                            <button onClick={closeModal} style={{ padding: '10px 40px', color: 'red' }} className='white-button'>Back</button>
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default AddSocialMediaModal 
