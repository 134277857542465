import React, { useEffect, useState } from 'react'
import styles from './Profile.module.css'
import { FaStar } from "react-icons/fa6";
import fb from '../../Assets/Icons/fb.png'
import ig from '../../Assets/Icons/ig.png'
import kakao from '../../Assets/Icons/kakao.svg'
import wechat from '../../Assets/Icons/wechat.png'
import Navbar from '../../Components/Navbar/Navbar'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';

const Profile = () => {
  const [experience, setExperience] = useState(false);
  const [skill, setSkill] = useState(false);
  const [knowledge, setKnowledge] = useState(false);
  const [communication, setCommunication] = useState(false);
  const [reliability, setReliability] = useState(false);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});
  const [feedback, setFeedback] = useState('');
  const [feedbacks, setFeedbacks] = useState([]);
  const [sentFeedback, setSentFeedback] = useState(false);

  // const userInfo = JSON.parse(window.localStorage.getItem('user'));

  useEffect(() => {
    if (isLoading) {
      axios.get('https://greencamel.zyberapi.site/feedback?sort=-createdAt&user=' + id)
        .then((e) => setFeedbacks(e.data.data))
        .catch((e) => console.log(e))

      axios.get('https://greencamel.zyberapi.site/user/' + id)
        .then((e) => {
          setIsLoading(false);
          setUser(e.data.data);
        })
        .catch((e) => console.log(e));
    }
  });

  const sendFeedBack = () => {
    setSentFeedback(true);
    axios.post('https://greencamel.zyberapi.site/feedback', {
      user: user._id,
      name: feedback,
      experience: experience,
      skill: skill,
      knowledge: knowledge,
      communication: communication,
      reliability: reliability,
    })
      .then(() => {
        window.location.reload(false);
        setSentFeedback(false);
      })
  }

  const calculateAverageRating = (feedbacks, attribute) => {
    const total = feedbacks.reduce((acc, curr) => {
      return acc + (curr[attribute] ? 1 : 0);
    }, 0);
    return (total / feedbacks.length) * 5; // Assuming the maximum rating is 5
  };

  return (
    <>
      {sentFeedback ? <div className="loader-container"><div className="rating-loader"></div></div> :
        isLoading ? <div className="loader-container"><div className='loader'></div></div > :
          <>
            <Navbar />
            <div className={styles.container}>
              <div className="space-between">
                <img className={styles.profile} src={'https://greencamel.zyberapi.site/uploads/' + user.profile} alt="" />
                <div className='column'>
                  <h3 className={styles.name}>{user.name}</h3>
                  <p className={styles.role}>{user.role}</p>
                </div>
                <div className='space-between'>
                  <FaStar color='#FFD700' size={25} />
                  {/* <p>4.8</p> */}
                  <p>{calculateAverageRating(feedbacks, 'experience').toFixed(1)}</p>
                </div>
              </div>
              <div className={styles.social_medias}>
                <a href={user.fb ? user.fb : "https://facebook.com"}>
                  <img className={styles.icon} src={fb} alt="" />
                </a>
                <a href={user.ig ? user.ig : "https://instagram.com"}>
                  <img className={styles.icon} src={ig} alt="" />
                </a>
                <a href={user.kakoa ? user.kakoa : "https://www.kakaocorp.com/page/service/service/KakaoTalk?lang=en"}>
                  <img className={styles.icon} src={kakao} alt="" />
                </a>
                <a href={user.platform ? user.platform : "https://www.wechat.com/"}>
                  <img className={styles.icon} src={wechat} alt="" />
                </a>
              </div>
              <div className={styles.icons}>
                <label>
                  <FaStar className={styles.star} onClick={() => setExperience(!experience)} color={experience ? '#FFD700' : '#d3d3d3'} size={25} />
                  <p>Experience - {feedbacks.filter((e) => e.experience === true).length}</p>
                </label>
                <label>
                  <FaStar className={styles.star} onClick={() => setSkill(!skill)} color={skill ? '#FFD700' : '#d3d3d3'} size={25} />
                  <p>Skill technique - {feedbacks.filter((e) => e.skill === true).length}</p>
                </label>
                <label>
                  <FaStar className={styles.star} onClick={() => setKnowledge(!knowledge)} color={knowledge ? '#FFD700' : '#d3d3d3'} size={25} />
                  <p>Knowledge - {feedbacks.filter((e) => e.knowledge === true).length}</p>
                </label>
                <label>
                  <FaStar className={styles.star} onClick={() => setCommunication(!communication)} color={communication ? '#FFD700' : '#d3d3d3'} size={25} />
                  <p>Communication - {feedbacks.filter((e) => e.communication === true).length}</p>
                </label>
                <label>
                  <FaStar className={styles.star} onClick={() => setReliability(!reliability)} color={reliability ? '#FFD700' : '#d3d3d3'} size={25} />
                  <p>Reliability - {feedbacks.filter((e) => e.reliability === true).length}</p>
                </label>
              </div>
              <div>
                Feedback:
                <br />
                <br />
                <textarea onChange={((e) => setFeedback(e.target.value))} placeholder='Feedback' name="" id="" cols="35" rows="10"></textarea>
              </div>
              <button onClick={sendFeedBack}>Send</button>
              Feedbacks:
              {
                feedbacks
                  .filter((e) => e.user === id)
                  .map((e) => (
                    <div>
                      <div className='line-start'>
                        {e.skill ?
                          <label htmlFor="">
                            <FaStar color='#FFD700' size={25} />
                            {/* skill */}
                          </label>
                          : null}
                        {e.reliability ?
                          <label htmlFor="">
                            <FaStar color='#FFD700' size={25} />
                            {/* reliability */}
                          </label>
                          : null}
                        {e.communication ?
                          <label htmlFor="">
                            <FaStar color='#FFD700' size={25} />
                            {/* communication */}
                          </label>
                          : null}
                        {e.knowledge ?
                          <label htmlFor="">
                            <FaStar color='#FFD700' size={25} />
                            {/* knowledge */}
                          </label>
                          : null}
                        {e.experience ?
                          <label htmlFor="">
                            <FaStar color='#FFD700' size={25} />
                            {/* experience */}
                          </label>
                          : null}
                      </div>
                      <br />
                      posted at: {dayjs(e.createdAt).format('YYYY-MM-DD')}
                      <p className='comment'>
                        {e.name}
                      </p>
                    </div>
                  ))
              }
              {/* <div>
          <FaStar color='#FFD700' size={25} />
          <FaStar color='#FFD700' size={25} />
          <FaStar color='#FFD700' size={25} />
          <FaStar color='#FFD700' size={25} />
          <FaStar color='#FFD700' size={25} />
          <p className='comment'>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi odit laudantium assumenda earum laborum accusantium quaerat enim tempore, minus
            quidem veritatis! Expedita necessitatibus similique minima non ducimus animi quidem eius autem labore vero, dolor, fugiat aperiam recusandae hic ullam
            voluptatibus debitis temporibus maiores sed quae deserunt. Esse dolorum officia voluptatem eaque soluta! Ea laborum minima labore! Explicabo reprehenderit,
            cum quasi dolorem, repellendus aspernatur, tempore expedita voluptatum quo est nobis facilis cumque quae in. Quas exercitationem dolor, nulla accusamus e
            a fugiat eius perferendis! Iste iusto numquam maxime. Voluptas, eligendi totam praesentium odio aperiam, molestias esse incidunt dolor a,
            aliquid id. Eligendi.
          </p>
        </div>
        <div>
          <FaStar color='#FFD700' size={25} />
          <FaStar color='#FFD700' size={25} />
          <p className='comment'>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi odit laudantium assumenda earum laborum accusantium quaerat enim tempore, minus
            quidem veritatis! Expedita necessitatibus similique minima non ducimus animi quidem eius autem labore vero, dolor, fugiat aperiam recusandae hic ullam
            voluptatibus debitis temporibus maiores sed quae deserunt.
          </p>
        </div> */}
            </div>
          </>}</>
  )
}

export default Profile
