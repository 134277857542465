import React, { useState } from 'react'
import styles from '../LoginPage.module.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SignUpPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [file, setFile] = useState('');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const signup = (ev) => {
        setIsLoading(true);
        ev.preventDefault();

        const data = new FormData();

        data.append('name', name);
        data.append('email', email);
        data.append('password', password);
        data.append('file', file);
        data.append('role', role);

        axios.post('https://greencamel.zyberapi.site/user/register', data)
            .then((e) => {
                setIsLoading(false);
                navigate('/');
            })
            .catch((e) => console.log(e))
    }

    return (
        <>{
            isLoading ? <div className='loader-container'><div className="loader"></div></div> :
                <div className={styles.container}>
                    <h2 className='logo'>Sign up</h2>
                    <label htmlFor="">
                        Username:
                        <input onChange={((e) => setName(e.target.value))} value={name} placeholder='Username' type="text" name="" id="" />
                    </label>
                    <label htmlFor="">
                        Email:
                        <input onChange={((e) => setEmail(e.target.value))} value={email} placeholder='Email' type="text" name="" id="" />
                    </label>
                    <label htmlFor="">
                        Password:
                        <input onChange={((e) => setPassword(e.target.value))} value={password} placeholder='Password' type="password" name="" id="" />
                    </label>
                    <label onChange={((e) => setRole(e.target.value))} htmlFor="">
                        Role:
                        <select name="" id="">
                            <option value="">Choose</option>
                            <option value="guide">Guide</option>
                            <option value="driver">Driver</option>
                        </select>
                    </label>
                    <label htmlFor="">
                        Profile:
                        <input onChange={((e) => setFile(e.target.files[0]))} type="file" name="" id="" />
                    </label>
                    <button onClick={signup} style={{ margin: '10px', padding: '10px 10px', width: '80vw' }} className='white-button'>Sign up</button>
                </div>
        }</>
    )
}

export default SignUpPage
