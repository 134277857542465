import React, { useEffect, useState } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import styles from './Land.module.css'
import LandingCard from '../../Uttils/Card/LandingCard'
import PlanPrice from '../../Uttils/PlanPrice/PlanPrice'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import dayjs from 'dayjs'

const Land = () => {
  const { id } = useParams()
  const [land, setLand] = useState({})
  const [plans, setPlans] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isLoading) {
      axios.get('https://greencamel.zyberapi.site/category/id/' + id)
        .then((e) => {
          setLand(e.data.data);
          setIsLoading(false);
        });
      axios.get('https://greencamel.zyberapi.site/plan')
        .then((e) => setPlans(e.data.data))
    }
  })
  return (
    <>
      <Navbar />
      {isLoading ? <div className="loader-container"><div className="loader"></div></div> :
        <div className={styles.container}>
          <div className={styles.header}>
            <img className={styles.img} src={'https://greencamel.zyberapi.site/uploads/' + land.cover} alt="" />
            <div className="column">
              <h2>{land.title}</h2>
              <a className='butotn' href={land.location} >Go to Maps</a>
              {/* <p className={styles.label}>Create: Zolboots</p> */}
              <p className={styles.label}>Created: {dayjs(land.createdAt).format('YYYY-MM-DD')}</p>
            </div>
          </div>
          <p>{land.content}</p>
          <div>
            <h3>Plans</h3>
            <div className="scroll-normal-row">
              <div className="normal-row">
                {plans.map((e) => (

                  <PlanPrice name={e.name} price={e.price} link={'https://greencamel.zyberapi.site/uploads/' + e.docx} />
                ))}
              </div>
            </div>
          </div>
          <div>
            <h3>Other Lands:</h3>
            {/* Other lands: */}
            <div className="scroll-row">
              <div className="row">
                <LandingCard />
                <LandingCard />
                <LandingCard />
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

export default Land
