import React, { useState } from 'react'
import styles from './LoginPage.module.css';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const login = () => {
        setIsLoading(true);
        axios.post('https://greencamel.zyberapi.site/user/login', {
            email: email,
            password: password
        }).then((e) => {
            window.localStorage.setItem('user', JSON.stringify(e.data.user));
            // alert('login');
            navigate('/');
            setIsLoading(false);
        })
            .catch((e) => console.log(e));
    }

    return (
        <>
            {isLoading ? <div className="loader-container"><div className="loader"></div></div> :
                <div className={styles.container}>
                    <h2 className='logo'>Welcome Back</h2>
                    <br />
                    <br />
                    <input style={{ margin: '10px', width: '80vw' }}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='Email' type="text" name="" id="" />
                    <input style={{ margin: '10px', width: '80vw' }}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder='Password' type="password" name="" id="" />
                    <br />
                    <button
                        style={{ margin: '10px', padding: '10px 10px', width: '80vw' }}
                        className='button'
                        onClick={login}
                    >
                        Login
                    </button>
                    <div className="line">
                        <div className="divider"></div>
                        <p className='text'>Or</p>
                        <div className="divider"></div>
                    </div>
                    <Link to={'/signup'} style={{ margin: '10px', padding: '10px 10px', width: '80vw' }} className='white-button'>Sign up</Link>
                </div>}
        </>
    )
}

export default LoginPage
